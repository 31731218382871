var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"users-table",attrs:{"headers":_vm.headers,"items":_vm.$store.getters['users/items'],"items-per-page":_vm.per_page,"page":_vm.page,"loading":_vm.$store.getters['users/loading'].fetchAll,"server-items-length":_vm.$store.getters['users/total'],"footer-props":_vm.$config.vDataTable.footerProps,"disable-sort":""},on:{"update:itemsPerPage":function($event){_vm.per_page=$event},"update:items-per-page":[function($event){_vm.per_page=$event},_vm.fetchData],"update:page":[function($event){_vm.page=$event},_vm.fetchData]},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"45"}},[_c('v-img',{attrs:{"src":_vm.getUserImage(item),"alt":"User image"}})],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$fullName(item))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$formatDate(item.created_at))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-select',{class:'status-' + item.status,attrs:{"items":_vm.statusItems,"loading":_vm.$store.getters['users/loading'].update == item.id,"readonly":_vm.isOrganisationOwner(item),"hide-details":"","solo":""},on:{"change":function($event){return _vm.updateStatus(item, $event)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!_vm.isOrganisationOwner(item))?_c('v-menu',{attrs:{"bottom":"","left":"","close-on-click":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"text-right"},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)],1)]}}],null,true),model:{value:(_vm.menu[item.id]),callback:function ($$v) {_vm.$set(_vm.menu, item.id, $$v)},expression:"menu[item.id]"}},[_c('v-card',{attrs:{"width":"150"}},[_c('UserDialog',{attrs:{"user":item,"button-text":_vm.$t('buttons.edit'),"button-icon":"mdi-pencil-outline","button-attrs":{
            class: 'btn-justify-left',
            block: true,
            color: 'transparent',
          }},on:{"click":function($event){_vm.menu[item.id] = false}}}),_c('confirm-dialog',{attrs:{"button-text":_vm.$t('buttons.delete'),"button-icon":"mdi-trash-can-outline","button-attrs":{
            class: 'btn-justify-left',
            block: true,
            color: 'transparent',
            loading: _vm.$store.getters['users/loading'].delete == item.id,
          }},on:{"confirm":function($event){return _vm.deleteUser(item)}}})],1)],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }